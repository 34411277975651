.missionDetails {
  line-height: 10px;
}
.missionDetails > p {
  font-size: 14px;
  font-weight: 400;
}
.missionStats {
}

.statNumber {
  font-size: 28px;
  font-weight: 500;
}

.statStatus {
  text-transform: uppercase;
  font-size: 18px;
  color: black;
}

.missionButton {
  font-size: 12px;
  width: 125px;
  margin-left: -3px;
  padding: 6px;
}

.custom-tabs {
  font-size: 14px;
}

.custom-tabs .icons {
  margin-left: 10px;
  font-size: 16px;
}

.missionDetails .active {
  color: green;
  font-weight: bold;
}

.inprogress {
  color: #fab300;
}

.submitted {
  color: #297eff;
}

.approved {
  color: green;
}
