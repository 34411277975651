/* body {
  font-family: 'Helvetica Neue', sans-serif !important;
}

.input-common {
  background: white;
  border-radius: 10px;
  border: 2px solid #cfcfd9;
}

.view {
  margin-right: 10px;
}

.ftr-btn {
  font-size: 12px;
  border-radius: 20px;
  width: 100px;
  height: 30px;
}

.inprogress {
  color: #fab300;
}

.submitted {
  color: #297eff;
}

.active {
  color: green;
}

.cardBorderTop {
  border-top: 10px solid black;
  margin-right: 20px;
  margin-bottom: 15px;
}

.talking-points {
  height: 300px;
  padding: 20px;
} */

#editor1 .ck.ck-editor__editable_inline {
  height: 302px !important;
}
